import '../scss/site.scss';

import onPageReady from './utils/onPageReady';
import polyfill from './polyfills/polyfill-loader';

// import shareWidget from './modules/share.widget.js';
// import { init as fedDocSearchInit } from './modules/fed-doc-search.js';

const sentryDsn = 'https://e3879c7fa2bef862f2d7c477db1efb0a@o37692.ingest.sentry.io/4505665755086848';

function initialise() {
    // fedDocSearchInit();

    if (sentryDsn) {
        import(/* webpackChunkName: "sentry" */ './modules/sentry.js')
            .then(({ default: sentry }) => {
                sentry.init(sentryDsn);
            });
    }

    /*  Mobile Menu  ------------------------ */
    const mobileNavEl = document.querySelector('.mobile-nav');
    if (mobileNavEl) {
        import(/* webpackChunkName: "mobile-nav" */ './modules/mobile.nav.js')
            .then(({ default: MobileNav }) => {
                new MobileNav(mobileNavEl);
            });
    }

    /*  Tables  ------------------------ */
    const tables = document.querySelectorAll('table');
    if (tables.length > 0) {
        import(/* webpackChunkName: "tables" */ './modules/responsive-tables.js')
            .then(({ default: tablesInit }) => {
                tablesInit([...tables]);
            });
    }

    /*  Collapsibles  ------------------------ */
    const collapsibleEls = document.querySelectorAll('[data-collapse-control], .collapse-control');
    if (collapsibleEls.length > 0) {
        import(/* webpackChunkName: "collapsible" */ './modules/collapsible.js')
            .then(({ default: collapsibleInit }) => {
                collapsibleInit([...collapsibleEls]);
            });
    }

    /*  Parallax  ------------- */
    const parallaxEls = document.querySelectorAll('.js-parallax');
    if (parallaxEls.length > 0) {
        import(/* webpackChunkName: "parallax" */ './modules/parallax.js')
            .then(({ default: parallaxInit }) => {
                parallaxInit([...parallaxEls]);
            });
    }

    /*  Simple pagination  ------------- */
    const paginationListingElem = document.querySelectorAll('[data-listing]');
    if (paginationListingElem.length > 0) {
        import(/* webpackChunkName: "simple-pagination" */ './modules/pagination.js')
            .then(({ default: paginationInit }) => {
                paginationInit(paginationListingElem[0]);
            });
    }

    /*  Simple filter  ------------- */
    const formElem = document.querySelectorAll('[data-filter-form]');
    const filterListingElem = document.querySelectorAll('[data-listing]');
    if (filterListingElem.length > 0 && formElem.length > 0) {
        import(/* webpackChunkName: "simple-filter" */ './modules/ajax-filter')
            .then(({ default: filterInit }) => {
                filterInit(formElem[0], filterListingElem[0]);
            });
    }

    /*  Glide slider block text only ------------- */
    const text_sliders = document.querySelectorAll('.glide--text-only');
    if (text_sliders.length > 0) {
        import(/* webpackChunkName: "glide_text_only" */ './modules/glide_text_only.js')
            .then(({ default: textSlidersInit }) => {
                textSlidersInit([...text_sliders]);
            });
    }

    /*  Glide slider block image and text ------------- */
    const img_text_sliders = document.querySelectorAll('.glide--image-and-text');
    if (img_text_sliders.length > 0) {
        import(/* webpackChunkName: "glide_img_text" */ './modules/glide_img_text.js')
            .then(({ default: imgTextSlidersInit }) => {
                imgTextSlidersInit([...img_text_sliders]);
            });
    }

    /*  Glide slider block people ------------- */
    const people_sliders = document.querySelectorAll('.glide--people');
    if (people_sliders.length > 0) {
        import(/* webpackChunkName: "glide_people" */ './modules/glide_people.js')
            .then(({ default: peopleSlidersInit }) => {
                peopleSlidersInit([...people_sliders]);
            });
    }

    /*  Video block  ------------- */
    const videoEls = document.querySelectorAll('[data-vimeo]');
    if (videoEls.length > 0) {
        import(/* webpackChunkName: "videoEls" */ './modules/video.js')
            .then(({ default: videoElsInit }) => {
                videoElsInit([...videoEls]);
            });
    }

    /*  Homepage hero banner  ------------- */
    const homeHeros = document.querySelectorAll('[data-home-hero]');
    if (homeHeros.length > 0) {
        import(/* webpackChunkName: "homeHero" */ './modules/home-hero-banner.js')
            .then(({ default: homeHerosInit }) => {
                homeHerosInit([...homeHeros]);
            });
    }

    /*  Standard hero banner  ------------- */
    const standardHeros = document.querySelectorAll('[data-standard-hero]');
    if (standardHeros.length > 0) {
        import(/* webpackChunkName: "standardHero" */ './modules/standard-hero-banner.js')
            .then(({ default: standardHerosInit }) => {
                standardHerosInit([...standardHeros]);
            });
    }

    /*  Homepage hero gridlines  ------------- */
    const rolloverCards = document.querySelectorAll('.rollover-card');
    if (rolloverCards.length > 0) {
        import(/* webpackChunkName: "rolloverCards" */ './modules/rollover_card.js');
    }

    /*  Contact form  ------------- */
    const contactFormLink = document.querySelectorAll('[data-contact-form]');
    if (contactFormLink.length > 0) {
        import(/* webpackChunkName: "contact-form" */ './modules/contact-form.js');
    }

    /*  Scroll reveal  ------------- */
    const scrollItems = document.querySelectorAll('[data-scrollreveal]');
    if (scrollItems.length > 0) {
        import(/* webpackChunkName: "scrollreveal" */ './modules/scrollreveal.js');
    }
}

polyfill().then(() => onPageReady(initialise));
